import type { ReactNode } from 'react';

import classNames from '../../../../../lib/classnames';
import Brand from '../../../../basic/Brand';
import Card from '../../../../basic/Card';

import BasicLayout from '..';

import type { ILayout } from '../../layout.interface';

export interface BasicCardLayoutProps extends ILayout {
    children: ReactNode;
    showBrand?: boolean;
    isBrandClickable?: boolean;
}

export default function BasicCardLayout({
    children,
    className,
    showBrand = true,
    isBrandClickable = true,
}: BasicCardLayoutProps): JSX.Element {
    return (
        <BasicLayout>
            <div className="m-auto p-4 md:p-0">
                {showBrand && <Brand clickable={isBrandClickable} />}
                <Card className={classNames('max-w-full md:w-[500px]', className)}>{children}</Card>
            </div>
        </BasicLayout>
    );
}
