import type { User } from 'next-auth';
import type { JWT } from 'next-auth/jwt';
import type { SignInResponse } from 'next-auth/react';
import { signIn } from 'next-auth/react';
import QueryString from 'qs';

import routes from '../../config/routes';
import { getAxiosAPI, postAxiosAPI } from '../../lib/request';
import type { IUser } from '../user';

const APP_URL = process.env.NEXT_PUBLIC_APP_URL;

type UserType = IUser & User;

export async function getUser(tokenJWT: string): Promise<UserType> {
    const queryStringUser = QueryString.stringify({
        populate: {
            avatar: {
                fields: ['url', 'name', 'provider_metadata'],
            },
        },
    });
    const { data } = await getAxiosAPI(`/users/me?${queryStringUser}`, tokenJWT);
    return data as UserType;
}

export async function googleProvider(token: JWT, accessToken: string): Promise<JWT> {
    const newToken = token;
    const response = await getAxiosAPI<{
        jwt: string;
        user: {
            id: string;
        };
    }>(`/auth/google/callback?access_token=${accessToken}`);
    const { data } = response;
    newToken.jwt = data.jwt;
    newToken.id = data.user.id;
    return newToken;
}

export function usernameLoginSignIn(
    email: string,
    password: string,
): Promise<SignInResponse | undefined> {
    return signIn('username-login', {
        redirect: false,
        email,
        password,
    });
}

export function magicSignIn(loginToken: string): Promise<SignInResponse | undefined> {
    return signIn('magic', {
        redirect: false,
        token: loginToken,
    });
}

export function confirmUserToken(confirmation: string): Promise<SignInResponse | undefined> {
    return signIn('confirm-token-login', {
        callbackUrl: `${APP_URL}${routes.onboardingProfile.getUrl()}`,
        confirmationToken: confirmation,
    });
}

export function googleSignIn(): Promise<SignInResponse | undefined> {
    return signIn('google');
}

export async function resetPassword(
    password: string,
    passwordConfirmation: string,
    code: string,
): Promise<void> {
    await postAxiosAPI(`/auth/reset-password`, {
        password,
        passwordConfirmation: password,
        code,
    });
}

export async function sendMagicLink(email: string, redirect?: string): Promise<void> {
    await postAxiosAPI(`/passwordless/send-link`, {
        email,
        redirect,
    });
}

export async function remindPassword(email: string): Promise<void> {
    await postAxiosAPI('/auth/forgot-password', { email });
}
